<template>
    <div class="w-screen flex items-start px-4 py-2 fixed z-50 header">
        <div class="w-1/12 flex">
        <!-- begin::Search -->
        <SearchBar class="ml-4"/>
        <!-- <Search class="ml-4 -mt-16" srcImage="/svg/loupe.svg" srcWidth="1.4rem" srcHeight="1.4rem"/> -->
        <!-- end::Search -->
         <!-- begin::locales-switcher -->
        <LocaleSwitcher fillColor="white"/>
        <!-- end::locales-switcher -->
        
        </div>
        <!-- begin::Main-menu -->
        <CoverPageMainMenu class=" w-10/12 items-center justify-center" />
        <!-- end::Main-menu -->
        <!-- begin::Menu-icon -->
        <HamburgerMenu class=" w-1/12 flex justify-end"/>
        <!-- end::Main-icon -->
    </div>
</template>
<style scoped>
.header{
  padding-top: 45px;
}
</style>
<script>
    // import Search from "@/components/search/Search";
    import CoverPageMainMenu from "@/layout/header/CoverPageMainMenu";
    import HamburgerMenu from "@/components/core/HamburgerMenu";
    import LocaleSwitcher from '@/components/core/LocaleSwitcher';
    import SearchBar from '@/components/core/SearchBar';

    export default {
        name: "CoverPageHeader",
        components: {HamburgerMenu, LocaleSwitcher, CoverPageMainMenu, SearchBar}
    }
</script>