<template>
    <div>
        <!-- begin::Main Layout -->
        <!-- begin::Header -->
        <div class="hidden lg:block">
            <Header v-if="showHeader"/>
            <CoverPageHeader v-show="showCoverPageHeader"/>
        </div>
        <!-- begin::MENU mobile -->
        <div class="lg:hidden">
            <MobileHeader pageType="main" />
        </div>
        <!-- end::MENU mobile -->
       
        <!-- end::Header -->

        <!-- begin::Page -->
        <router-view @show-header="revealHeader"/>
        <!-- end::Page -->
        <!-- <Footer /> -->
        <!-- end::Main Layout -->
        <!-- begin::MENU mobile -->
        <div class="lg:hidden">
            <MobileMenu v-if="showMobileMenu"/>
        </div>
        <!-- end::MENU mobile -->
        <Cookies @cookie-accepted="cookieAccepted" @cookie-closed="cookieClosed" v-if="!LACookie"/>
    </div>
</template>

<script>
    import Header from '@/layout/header/Header'
    import CoverPageHeader from '@/layout/header/CoverPageHeader'
    import MobileHeader from '@/layout/header/mobile/MobileHeader'
    import Cookies from '@/components/modal/Cookies'
    import MobileMenu from '@/layout/header/mobile/MobileMenu'
    // import Footer from '@/layout/footer/FooterExtended'
    export default {
        components: {
            Header,
            CoverPageHeader,
            MobileHeader,
            Cookies,
            MobileMenu
            // Footer
        },
        data: function(){
            return{
                showHeader: false,
                showCoverPageHeader: false,
                showMobileMenu: true,
                scrollYPosition: 0,
                LACookie: false
            }
        },
        created(){
            window.addEventListener('scroll', this.handleScroll);
            if(this.$cookies.get('LACookie') != null && this.$cookies.get('LACookie') == 'true'){
              this.LACookie = true
            }
        },
        methods:{
            cookieClosed(){
                this.LACookie = true
            },
            cookieAccepted(){
                this.LACookie = true
            },
            revealHeader(){
              this.handleScroll()
            },            
            handleScroll(){
                if(this.$route.name == 'privacy'){
                    this.showHeader = false
                    this.showCoverPageHeader = false
                    return
                }

                if(this.$route.name != 'home'){
                  this.showHeader = true
                  this.showCoverPageHeader = false
                }else {
                  if(window.scrollY >= 50){
                    this.showHeader = true
                    this.showCoverPageHeader = false
                  }else {
                    this.showHeader = false
                    this.showCoverPageHeader = true
                  }
                }
                if(this.$route.name == 'sellwithus' || this.$route.name == 'ThisIsLA' || this.$route.name == 'listing' || this.$route.name == 'development'){
                    this.showMobileMenu = false;
                }
                else{
                    this.showMobileMenu = true;
                }
            },
        }
    }
</script>