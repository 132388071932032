<template>
    <div>
        <!-- Desktop -->
        <div class="hidden lg:block fixed bg-white rounded-lg bottom-6 right-6  border border-bronze 
            lg:z-40 w-1/5 h-40 lg:bottom-6 lg:right-6 lg:w-1/4
            sm:z-40 sm:bottom-6 sm:right-6 lg:h-40 sm:w-2/4">
            <div class="flex items-center flex-col px-5 md:px-0">
                <p class="m-4 font-euclid">
                    {{ $t('Cookies.text') }}                 
                    <a  class="font-bold underline cursor-pointer" @click="generateLink">{{ $t('Cookies.privacy') }}</a> 
                    {{ $t('Cookies.text2') }}
                </p>
            </div>
            <div class="w-full flex">
                <div class="w-9/12 ml-4"> 
                    <button 
                        @click="acceptCookie"
                        form="messageForm"
                        type="submit"
                        class="mt-3 bg-black rounded-lg hover:bg-bronze font-medium uppercase text-sm tracking-button inline-flex items-center justify-center relative transition duration-500 ease-in-out text-white w-full py-2 md:py-3">
                        <span>{{ $t('Cookies.gotIt') }}</span>
                    </button>
                </div>
                <div class="flex justify-end w-3/12 mt-4 mr-4">
                    <button class="close z-40"
                        @click="closeCookie">
                        <inline-svg class="transform origin-center rotate-45 w-3 h-3 md:w-5 md:h-5 lg:w-10 lg:h-10" fill="black"
                            src="/svg/plus.svg" />
                    </button>
                </div>
            </div>

        </div>
        <!-- End: Desktop -->

        <!-- Mobile -->
        <div class="lg:hidden fixed bg-white bottom-0 border border-bronze w-full h-12 z-60 text-sm">
            <div class="flex items-center flex-col ml-4 mr-4 mt-1 md:px-0">
                <p class="font-euclid">
                    {{ $t('Cookies.text') }}                 
                    <a  class="font-bold underline cursor-pointer" @click="generateLink">{{ $t('Cookies.privacy') }}</a> 
                    {{ $t('Cookies.text2') }}
                </p>
            </div>
            <div class="w-full flex">
                <div class="w-4/5 ml-4 content-end flex justify-end"> 
                    <button 
                        @click="acceptCookie"
                        form="messageForm"
                        type="submit"
                        class="mt-1 bg-black rounded-lg hover:bg-bronze font-medium uppercase text-sm tracking-button inline-flex items-center justify-center relative transition duration-500 ease-in-out text-white w-1/2 py-1 md:py-1">
                        <span>{{ $t('Cookies.gotIt') }}</span>
                    </button>
                </div>
                <div class="flex justify-end w-1/5 mt-1 mr-4">
                    <button class="close z-40"
                        @click="closeCookie">
                        <inline-svg class="transform origin-center rotate-45 w-3 h-3 md:w-5 md:h-5 lg:w-10 lg:h-10" fill="black"
                            src="/svg/plus.svg" />
                    </button>
                </div>
            </div>
        </div>
        <!-- End: Mobile -->
    </div>
</template>
<style scoped>
    .z-60{
        z-index:60;
    }
</style>
<script>
export default {
    name: "Cookies",
    methods:{
        generateLink(){
            let routeData = this.$router.resolve({name: 'privacy', params: { lang: this.$i18n.locale }});
            window.open(routeData.href, '_blank');
        },
        acceptCookie(){
            this.$cookies.set("LACookie", true, '1D')
            this.$emit('cookie-accepted')
        },
        closeCookie(){
            this.$emit('cookie-closed')
        }
    }
}
</script>