<template>
  <ul class="font-blackMango font-black tracking-wider uppercase flex text-sm text-white text-opacity-50 menu">
    <!-- TO DO: turn list a v-for cycle -->
    
    <li class="mx-3 text-opacity-100">
      <a :id="'MenuHome'" @click="goTo('home')" :class="isHome ? 'cover-page-header-router-link-active' : ''">
        {{ $t('main.mainMenu.home') }}
      </a>
    </li>
    <li class="mx-3">
      <a id="MenuListings" @click="goToEgo('listings')" :class="isListings ? 'cover-page-header-router-link-active' : ''">
        {{ $t('main.mainMenu.listings') }}
      </a>
    </li>
    <li class="mx-3">
      <a id="MenuListings" @click="goToEgo('newDevelopments')" :class="isListings ? 'router-link-active' : ''">
        {{ $t('main.mainMenu.newDevelopments') }}
      </a>
    </li>
    <li class="mx-3">
      <a :id="'MenuCollection'" @click="goTo('collection')">
        {{ $t('main.mainMenu.collection') }}
      </a>
    </li>
    <li class="mx-3">
      <a id="MenuServices" @click="goTo('services')">
        {{ $t('main.mainMenu.services') }}
      </a>
    </li>
    <li class="mx-3">
      <a id="MenuThisIsLa" @click="goTo('thisisla')" :class="isThisIsLA ? 'cover-page-header-router-link-active' : ''">
        {{ $t('main.mainMenu.thisisLA') }}
      </a>
    </li>
    <li class="mx-3">
      <a id="MenuDistricts" @click="goTo('districts')">
        {{ $t('main.mainMenu.districts') }}
      </a>
    </li>
    <li class="mx-3">
      <a id="MenuContact" @click="goTo('contact')">
        {{ $t('main.mainMenu.zoom') }}
      </a>
    </li>
    <li class="mx-3">
      <a id="MenuSellWithUs" @click="goTo('sellwithus')" :class="isSellWithUs ? 'cover-page-header-router-link-active' : ''">
        {{ $t('main.mainMenu.sell') }}
      </a>
    </li>
  </ul>
</template>
<style scoped>
.menu{
  align-items: flex-start;
  font-size: calc(0.38rem + 0.45vw);
}
.menu-icon{
  margin-top: -25px;
}
.menu a {
  cursor: pointer;
}
</style>
<script>
export default {
    name: "CoverPageMainMenu",
    data: function () {
      return {
        currentTarget:''
      }
    },
    computed: {
      isHome(){
        return ('home' == this.$route.name) && (this.$route.hash == '');
      },
      isHomeNewDevelopments(){
        return ('home' == this.$route.name) && (this.$route.hash == '#newDevelopments_content');
      },
      isHomeCollection(){
        return ('home' == this.$route.name) && (this.$route.hash == '#collection_content');
      },
      isHomeServices(){
        return ('home' == this.$route.name) && (this.$route.hash == '#services_content');
      },
      isHomeContact(){
        return ('home' == this.$route.name) && (this.$route.hash == '#contact_content');
      },
      isDistricts(){
        return ('districts' == this.$route.name) || (this.$route.hash == '#districts_content');
      },
      isListings(){
        return ('listings' == this.$route.name);
      },
      isThisIsLA(){
        return ('ThisIsLA' == this.$route.name);
      },
      isSellWithUs(){
        return ('sellwithus' == this.$route.name);
      },
    },
    methods:{
      goToEgo(p){
        let isEnglish = this.$i18n.locale == 'en'
        if(p == 'listings'){
            if(isEnglish){
                window.location.href = this.$ego_redirecter() + '/en-gb/properties'
            }else{
                window.location.href = this.$ego_redirecter() + '/imoveis'
            }                
        }
        if(p == 'newDevelopments'){
            if(isEnglish){
                window.location.href = this.$ego_redirecter() + '/en-gb/developments'
            }else{
                window.location.href = this.$ego_redirecter() + '/empreendimentos'
            }                
        }
      },
      checkCurrent(){
        if(this.isHome) return 'home';
        if(this.isHomeNewDevelopments) return 'newDevelopments';
        if(this.isHomeCollection) return 'collection';
        if(this.isHomeServices) return 'services';
        if(this.isHomeContact) return 'contact';
        if(this.isDistricts) return 'districts';
        if(this.isListings) return 'listings';
        if(this.isThisIsLA) return 'thisisla';
        if(this.isSellWithUs) return 'sellwithus';
      },
      goTo(target){
        if(target == this.checkCurrent()){
          return;
        }
        switch(target){
          case 'home':
            this.$router.push({ name: target, params: { lang: this.$i18n.locale}})
            break;
          case 'newDevelopments':
              this.$router.push({ name: 'home', hash: '#newDevelopments_content', params: { lang: this.$i18n.locale}})
            break;
          case 'collection':
            this.$router.push({ name: 'home', hash: '#collection_content', params: { lang: this.$i18n.locale}})
            break;
          case 'listings':
            this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: '0'}})
            break;
          case 'districts':
                this.$router.push({ name: 'districts', params: { lang: this.$i18n.locale, slug: 2 }})
            break;
          case 'services':
            this.$router.push({ name: 'home', hash: '#services_content', params: { lang: this.$i18n.locale}})
            break;
          case 'thisisla':
            this.$router.push({ name: 'ThisIsLA', params: { lang: this.$i18n.locale}})
            break;
          case 'contact':
            this.$router.push({ name: 'home', hash: '#contact_content', params: { lang: this.$i18n.locale}})
            break;
          case 'sellwithus':
            this.$router.push({ name: 'sellwithus', params: { lang: this.$i18n.locale}})
            break;
        }
      },
    }
}
</script>